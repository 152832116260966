import { ApolloClient, gql } from '@apollo/client';

import { gqlSimpleQueryAsPromise } from 'common/graphql/utils';
import { generateLocalTimeGql } from 'common/utils/common.utils';
import {
    InputSubmitPatientICFSignatures,
    InputSubmitPatientICFSignaturesVars,
    InputUpdateICFTemplateVersion,
    InputUpdateICFTemplateVersionVars,
    InputUpdatePatientICF,
    InputUpdatePatientICFVars,
    LocalTime,
    PatientICF,
} from 'module/econsent/types/types';

interface EconsentSignature {
    pk: string;
    sk: string;
    data: string;
    firstName: string;
    lastName: string;
    signatureHash: string;
    attempts: number;
    attemptsRemaining: number;
    createdAt: string;
    createdAtLocal: LocalTime;
    updatedAt: string;
    updatedAtLocal: LocalTime;
    tn: string;
    schema: string;
    isDeleted: number;
    version: number;
}

const econsentSignatureFragment = gql`
    fragment econsentSignature on EconsentSignature {
        pk
        sk
        data
        firstName
        lastName
        signatureHash
        attempts
        attemptsRemaining
        tn
        schema
        version
        isDeleted
        createdAt
        createdAtLocal {
            timezone
            timestamp
        }
        updatedAt
        updatedAtLocal {
            timezone
            timestamp
        }
    }
`;

export const getSignatureFragment = `
    fragment signatureIcf on ICFSignature {
        icfQuestionId
        userId
        dateSigned
        firstName
        lastName
        econsentSignature {
            firstName
            lastName
            __typename
        }
        __typename
    }
`;

export const getPatientICFFragment = `
    ${getSignatureFragment}

    fragment patientIcf on PatientICF {
        pk
        sk
        isDeactivated
        siteICF {
            pk
            sk
            name
            siteIcfFileVersion
            s3KeyPresignedUrl
            files {
                language
                s3KeyPresignedUrl
            }
            translatedFields {
                fields {
                    language
                    translation
                    itemField
                }
            }
            __typename
        }
        answers {
            pk
            sk
            icfQuestionId
            value
            version
            __typename
        }
        patientId
        hasQuestions
        isConsentOptional
        patientSignatures {
            ...signatureIcf
        }
        caregiverSignatures {
            ...signatureIcf
        }
        clinicianSignatures {
            ...signatureIcf
        }
        investigatorSignatures {
            ...signatureIcf
        }
        hasQuestions
        hasPatientSigned
        hasCaregiverSigned
        hasClinicianSigned
        requiresCaregiverSignature
        hasPiSigned
        workflowStep
        workflowHistory {
            workflowStep
            startedAt
            endedAt
        }
        statusPatient
        statusCaregiver
        statusPi
        statusClinician
        status
        version
        __typename
    } 
`;

export const getPatientICF = gql`
    ${getPatientICFFragment}

    query getPatientICF($pk: String!, $sk: String!) {
        getPatientICF(pk: $pk, sk: $sk) {
            ...patientIcf
        }
    }
`;

export const updateEconsentSignature = (
    userPk: string,
    oldPassword: string,
    newPassword: string
): Promise<EconsentSignature> => {
    return gqlSimpleQueryAsPromise<EconsentSignature>(
        gql`
            ${econsentSignatureFragment}

            mutation updateEconsentSignature(
                $userPk: String!
                $oldPrivateKey: String!
                $newPrivateKey: String!
                $localtime: InputLocalTime
            ) {
                updateEconsentSignature(
                    input: { pk: $userPk, oldPrivateKey: $oldPrivateKey, newPrivateKey: $newPrivateKey }
                    localtime: $localtime
                ) {
                    ...econsentSignature
                }
            }
        `,
        {
            queryString: 'updateEconsentSignature',
            variables: {
                userPk,
                oldPrivateKey: oldPassword,
                newPrivateKey: newPassword,
                localtime: generateLocalTimeGql(),
            },
        }
    );
};

export const updateEconsentSignatureName = (
    userPk: string,
    firstName: string,
    lastName: string
): Promise<EconsentSignature> => {
    return gqlSimpleQueryAsPromise<EconsentSignature>(
        gql`
            ${econsentSignatureFragment}

            mutation updateEconsentSignatureName(
                $userPk: String!
                $firstName: String
                $lastName: String
                $localtime: InputLocalTime
            ) {
                updateEconsentSignatureName(
                    input: { pk: $userPk, firstName: $firstName, lastName: $lastName }
                    localtime: $localtime
                ) {
                    ...econsentSignature
                }
            }
        `,
        {
            queryString: 'updateEconsentSignatureName',
            variables: {
                userPk,
                firstName,
                lastName,
                localtime: generateLocalTimeGql(),
            },
        }
    );
};

export const getPatientICFs = async (pk: string, sk: string) => {
    return await gqlSimpleQueryAsPromise<PatientICF>(getPatientICF, {
        queryString: 'getPatientICF',
        variables: {
            pk,
            sk,
        },
        generateFields: ['siteICF', 'presignedUrl', 'translations'],
    });
};

export const signPatientICF = async (
    pk: string,
    sk: string,
    icfQuestionId: string,
    expectedVersion: number,
    signatureToken: string
) => {
    const response = await gqlSimpleQueryAsPromise<PatientICF>(
        gql`
            ${getPatientICFFragment}

            mutation signPatientICF($input: InputSignPatientICF, $localtime: InputLocalTime, $rfc: [InputRFC]) {
                signPatientICF(input: $input, localtime: $localtime, rfc: $rfc) {
                    ...patientIcf
                }
            }
        `,
        {
            queryString: 'signPatientICF',
            variables: {
                input: { pk, sk, expectedVersion, icfQuestionId, signatureToken },
                localtime: generateLocalTimeGql(),
            },
            generateFields: ['siteICF', 'presignedUrl', 'translations'],
        }
    );

    return response;
};

export const submitPatientICFSignatures = async (
    client: ApolloClient<unknown>,
    pk: string,
    sk: string,
    expectedVersion: number
) => {
    const response = await client.mutate<InputSubmitPatientICFSignatures, InputSubmitPatientICFSignaturesVars>({
        mutation: gql`
            ${getPatientICFFragment}

            mutation submitPatientICFSignatures($input: InputSubmitPatientICFSignatures!, $localtime: InputLocalTime) {
                submitPatientICFSignatures(input: $input, localtime: $localtime) {
                    ...patientIcf
                }
            }
        `,
        variables: {
            input: { pk, sk, expectedVersion },
            localtime: generateLocalTimeGql(),
        },
        context: {
            generateFields: ['siteICF', 'presignedUrl', 'translations'],
        },
    });

    return response;
};

export const getICFStudyData = gql`
    query getStudy($pk: String!) {
        getStudy(pk: $pk) {
            pk
            sk
            displayName
            languages
            sites {
                sk
                displayName
            }
            __typename
        }
    }
`;

export const getTemplateVersionFragment = `
    fragment icfTemplateVersion on ICFTemplateVersion {
        pk
        sk
        data
        name
        status
        countries
        languages
        sites {
            siteId
            irbStatus
            irbStatusDate
            createdAt
            updatedAt
        }
        languages
        files {
            language
            s3KeyPresignedUrl
        }
        translatedFields {
            fields {
                language
                itemField
                translation
                version
            }
        }
        irbName
      	irbVersion
        s3Key
        s3KeyPresignedUrl
        templateVersion
        templateId
        createdAt
        updatedAt
        version
        __typename
    }
`;

export const getTemplateDataFragment = `
    ${getTemplateVersionFragment}

    fragment icfDocumentTemplate on ICFTemplate {
        pk
        sk
        data
        templateId
        name
        templateVersions {
            ...icfTemplateVersion
        }
        version
        isDeleted
        createdAt
        updatedAt
        version
        __typename
    }
`;

export const getSiteICFFragment = `
    ${getTemplateVersionFragment}

    fragment icfSiteBuilder on SiteICF {
        pk
        sk
        icfTemplateId
        icfTemplateVersionId
        icfTemplateVersion {
            ...icfTemplateVersion
        }
        languages
        files {
            language
            s3KeyPresignedUrl
        }
        translatedFields {
            fields {
                language
                itemField
                translation
                version
            }
        }
        studyId
        siteId
        name
        status
        releaseDate
        s3KeyPresignedUrl
        templateFileVersion
        siteIcfFileVersion
        version
        __typename
    }
`;

export const getSiteICFAggregateFragment = `
    fragment icfSite on SiteICF {
        pk
        sk
        version
        isDeleted
        isConsentOptional
        allowPaperConsent
        icfTemplateVersionId
        icfTemplateVersion {
            pk
            sk   
            name
            templateId
            templateVersion
            irbName
      	    irbVersion
        }
        studyId
        siteId
        name
        status
        releaseDate
        irbName
        irbStatus
        irbVersion
        irbStatusDate
        countries
        languages
        templateFileVersion
        siteIcfFileVersion
        createdAt
        updatedAt
        __typename
    }	
`;

export const getSiteAggregateFragment = `
    ${getSiteICFAggregateFragment}

    fragment siteIcf on AggregateSiteICF {
        studyId
        siteId
        icfTemplateId
        siteICFVersions {
            ...icfSite 
        }
        __typename
    }
`;

export const getTemplateVersion = gql`
    ${getTemplateVersionFragment}

    query getIcfDocumentTemplateVersion($pk: String!, $sk: String, $studyId: String) {
        getICFTemplateVersion(pk: $pk, sk: $sk, studyId: $studyId) {
            ...icfTemplateVersion
        }
    }
`;

export const getSiteICF = gql`
    ${getSiteICFFragment}

    query getSiteICF($pk: String!, $sk: String!) {
        getSiteICF(pk: $pk, sk: $sk) {
            ...icfSiteBuilder
        }
    }
`;
export const updatePatientICF = async (client: ApolloClient<unknown>, values, reasonsToDecline = []) => {
    const response = await client.mutate<InputUpdatePatientICF, InputUpdatePatientICFVars>({
        mutation: gql`
            ${getPatientICFFragment}

            mutation updatePatientICF($input: InputUpdatePatientICF, $localtime: InputLocalTime, $rfc: [InputRFC]) {
                updatePatientICF(input: $input, localtime: $localtime, rfc: $rfc) {
                    ...patientIcf
                }
            }
        `,
        variables: {
            input: { ...values },
            localtime: generateLocalTimeGql(),
            rfc: reasonsToDecline,
        },
        context: {
            generateFields: ['siteICF', 'presignedUrl', 'translations'],
        },
    });

    return response;
};

export const updateSiteICFTemplate = async (client: ApolloClient<unknown>, values) => {
    const response = await client.mutate({
        mutation: gql`
            ${getSiteAggregateFragment}

            mutation updateSiteIcf($input: InputUpdateSiteICF!, $localTime: InputLocalTime) {
                updateSiteICF(input: $input, localtime: $localTime) {
                    ...siteIcf
                }
            }
        `,
        variables: {
            input: { ...values },
            localTime: generateLocalTimeGql(),
        },
    });

    return response;
};

export const updateICFTemplateVersion = async (client: ApolloClient<unknown>, values) => {
    const response = await client.mutate<InputUpdateICFTemplateVersion, InputUpdateICFTemplateVersionVars>({
        mutation: gql`
            ${getTemplateDataFragment}

            mutation updateIcfDocumentTemplateVersion(
                $input: InputUpdateICFTemplateVersion!
                $localTime: InputLocalTime
            ) {
                updateICFTemplateVersion(input: $input, localtime: $localTime) {
                    ...icfDocumentTemplate
                }
            }
        `,
        variables: {
            input: { ...values },
            localTime: generateLocalTimeGql(),
        },
    });

    return response;
};
